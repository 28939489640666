head {
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
}
body {
    margin: 0;
    padding: 0;
}
h1 {
    font-size: 24px;
    margin: 0;
    padding: 0;
}
h2 {
    font-size: 20px;
    margin: 0;
    padding: 0;
}
h3 {
    font-size: 18px;
    margin: 0;
    padding: 0;
}