head {
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

html {
  background-color: azure;
}
/*# sourceMappingURL=index.f8ee1f43.css.map */
